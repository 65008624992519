<template >
  <div class="page flex-col">
    <div class="group_1 flex-row" >
      <div class="group_1_left">个人信息</div>
    </div>

    <div class=" group_account_center_buttom">

      <el-row>
        <el-col :span="3" style="padding: 1vw 0vw 0vw 4vw">
          <img style="width: 4.5vw;height: 4.5vw" :src=user.headImgUrl />
        </el-col>
        <el-col :span="21">
          <el-row style="margin-top: 0vw">
            <span class="buyer_font_1vw">真实姓名：   {{ user.userTrueName }}</span>
          </el-row>
          <el-row style="margin-top: 1vw">
            <span class="buyer_font_1vw">联系地址：{{ user.userAddress }}</span>
          </el-row>
          <el-row style="margin-top: 1vw">
            <span class="buyer_font_1vw">省/市/区：{{ (user.province!=null?user.province+' ':'') + (user.city!=null?user.city+' ':'') + (user.region!=null?user.region:'') }}</span>
          </el-row>
          <el-row style="margin-top: 1vw">
            <span class="buyer_font_1vw">性别：{{ user.userSex =='1'?'男': user.userSex == '0'?'保密':'女' }}</span>
          </el-row>
          <el-row style="margin-top: 1vw">
            <span class="buyer_font_1vw">生日：{{ user.userBirthday }}</span>
          </el-row>
          <el-row style="margin-top: 1vw">
            <span class="buyer_font_1vw">邮箱：{{ user.email }}</span>
          </el-row>
          <el-row style="margin-top: 1vw">
            <span class="buyer_font_1vw">手机号：{{ user.phone }}</span>
          </el-row>
          <el-row style="margin-top: 2vw">
            <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;" @click="editInfo">编辑信息</el-button>
          </el-row>
        </el-col>

      </el-row>
    </div>

    <el-drawer
        title="编辑账号"
        :visible.sync="editDrawer"
        direction="rtl"
        size="550px"
        class="drawer">
      <el-form :model="editNumberValidateForm" ref="editNumberValidateForm" label-width="100px" class="demo-ruleForm">
        <el-form-item
            label="用户昵称"
            prop="name"
            :rules="[
           { required: true, message: '用户昵称不能为空'},
          ]">
          <el-input v-model="editNumberValidateForm.name" autocomplete="off" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item
            label="手机号码"
            prop="phone"
            :rules="rules.phone">
          <el-input type="phone" v-model.number="editNumberValidateForm.phone" autocomplete="off" placeholder="请输入收件人手机号码"></el-input>
        </el-form-item>

        <el-form-item
            label="邮箱"
            prop="email"
            :rules="rules.email">
          <el-input type="email" disabled v-model="editNumberValidateForm.email" placeholder="请输入收件人邮箱"></el-input>
        </el-form-item>

        <el-form-item
            label="真实姓名"
            prop="userTrueName">
          <el-input type="text" v-model="editNumberValidateForm.userTrueName" autocomplete="off" placeholder="请输入..."></el-input>
        </el-form-item>

        <el-form-item
            label="性别"
            prop="userSex">
          <el-radio-group v-model="editNumberValidateForm.userSex">
            <el-radio :label="0">保密</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
            label="生日"
            prop="type">
          <el-date-picker
              v-model="editNumberValidateForm.userBirthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期" class="margin-r">
          </el-date-picker>
        </el-form-item>

        <el-form-item
            label="上传文件"
            prop="file"
        >
          <el-upload
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :http-request="uploadFileOrImg"
              :before-upload="beforUpload"
              multiple
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="editNumberValidateForm.urlList">
            <el-button size="small">点击上传</el-button>
          </el-upload>

        </el-form-item>

        <el-form-item
            label="联系地址"
            prop="userAddress">
          <el-input  v-model="editNumberValidateForm.userAddress" autocomplete="off" placeholder="请输入..."></el-input>
        </el-form-item>

        <el-form-item
            label="省/市/区"
            prop="address">
          <el-cascader
              ref="cascaderEdit"
              :data="options"
              :props="{label:'label',value:'label',children:'children' }"
              @change="editCascaderHandleChange"
              :value="editCascader"
              :options="options"
              placeholder="请选择城市"
          ></el-cascader>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="syncUserInfo()">提交</el-button>
          <el-button @click="editDrawer = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>


import {optionsData} from "../../../utils/china-area";
import api from "../../../api/buyer/addWork/addWorkApi";

export default {
  name: "buyerIndex",
  data() {
    return {
      date: '',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      company: {},    //企业信息
      user: {},   //用户信息
      editDrawer:false,
      editNumberValidateForm: {
        name:'',
        phone:'',
        email: '',
        userTrueName: '',
        userSex: '',
        userBirthday:'',
        urlList:[],
        userAddress:''
      },
      editCascader:[],
      rules: {
        email: [
          {required: true, message: '邮箱不能为空'},
          {type: 'email', message: '邮件格式不正确', trigger: 'change'}
        ],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'change' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
              console.log(passwordreg.test(value))
              if (!passwordreg.test(value)) {
                callback(
                    new Error(
                        '手机号码格式不正确'
                    )
                )
              } else {
                callback()
              }
            }
          }
        ],
        postalCode: [
          { required: true, message: '邮政编码不能为空', trigger: 'change'},
          { type: 'integer', message: '邮政编码格式不正确', trigger: 'change'},
          {
            trigger: 'change',
            validator : (rule, value, callback) => {
              if(!/^[1-9]\d{5}$/.test(value)) {
                return callback(new Error("邮政编码格式不正确。"));
              }
              callback();
            }
          }
        ],
        postalCodeEdit: [
          { required: true, message: '邮政编码不能为空', trigger: 'change'},
          {
            trigger: 'change',
            validator : (rule, value, callback) => {
              if(!/^[1-9]\d{5}$/.test(value)) {
                return callback(new Error("邮政编码格式不正确。"));
              }
              callback();
            }
          }
        ]
      },
      options: optionsData(),
    }
  },
  mounted() {
    this.userInfo();
  },
  filters: {
    filterTel(val) {
      if(val) {
        return (val.substring(3, 0)) + '****' + (val.substring(7));
      }
    }
  },
  methods: {
    //用户信息
    userInfo() {
      api.getUserInfo({}).then(res => {
        if(res.data) {
          this.user = res.data;
        }
      })
    },
    editInfo(){
      this.editDrawer = true;

      this.$nextTick(()=>{
        this.editCascader = [];

        this.resetForm('editNumberValidateForm');
        this.editNumberValidateForm.urlList = [];
        this.editNumberValidateForm.name = this.user.name;

        console.log(this.editNumberValidateForm)

        this.editNumberValidateForm.phone = this.user.phone
        this.editNumberValidateForm.email = this.user.email
        this.editNumberValidateForm.userTrueName = this.user.userTrueName
        this.editNumberValidateForm.userSex = this.user.userSex
        this.editNumberValidateForm.userBirthday = this.user.userBirthday
        if(this.user.headImgUrl) {
          this.editNumberValidateForm.urlList.push({urlType: 1, name: this.user.headImgUrl, url: this.user.headImgUrl})
        }
        this.editNumberValidateForm.region = this.user.region
        this.editNumberValidateForm.addressId = this.user.addressId
        this.editNumberValidateForm.address = this.user.province

        this.editNumberValidateForm.province = this.user.province
        this.editNumberValidateForm.city = this.user.city
        let list = [];
        list.push(this.user.province)
        list.push(this.user.city)
        list.push(this.user.region)
        this.editCascader=list
        console.log(this.editCascader)
        console.log(123)
        this.editNumberValidateForm.userAddress = this.user.userAddress
        //this.resetForm('editNumberValidateForm');
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    syncUserInfo(){
      this.editNumberValidateForm.userId = this.user.userId;
      this.editNumberValidateForm.companyId = this.user.companyId;
      this.editNumberValidateForm.userExtendsId = this.user.userExtendsId;
      this.$refs['editNumberValidateForm'].validate((valid) => {
        if (valid) {
          let assign1 = Object.assign({},this.editNumberValidateForm);
          console.log(assign1)
          api.syncUserInfo(assign1).then((res)=>{
            if (res.code == 200){
              this.editDrawer = false
              this.userInfo();
            }
          })
        } else {
          return false;
        }
      });
    },
    onSubmit() {
    },
    editCascaderHandleChange(row){
      if(row){
        if(row[0]){
          this.editNumberValidateForm.province = row[0]
        }
        if(row[1]){
          this.editNumberValidateForm.city = row[1]
        }
        if(row[2]){
          this.editNumberValidateForm.region = row[2]
        }
        this.editNumberValidateForm.address = row
      }
      console.log(123)
      console.log(row)
      let list = [];
      list.push(row[0])
      list.push(row[1])
      list.push(row[2])
      console.log(list)
      this.editCascader=list
    },
    handleRemove(file, fileList) {
      this.editNumberValidateForm.urlList = [];
    },
    handlePreview(file) {
    },
    handleExceed(files, fileList) {
      //this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message({
        message: `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        type: 'warning',
        offset: 80
      });
    },
    beforUpload(file){
      let limitImg = ['.png','.jpg','.bmp','.gif']
      let name = file.name;
      let i = name.lastIndexOf(".");
      var suffix = "";
      if (i > -1) {
        suffix = name.substring(i);
      }
      const number1 = limitImg.indexOf(suffix);
      if(number1<=-1){
        this.$message({
          message: '只能上传.png,.jpg,.bmp,.gif文件',
          type: 'error',
          offset: 80
        });
        return false;
      }
    },
    uploadFileOrImg(param){
      const formData = new FormData();
      formData.append('file',param.file)
      //return;
      let imgSuffix= ['.png', '.jpg', '.bmp', '.gif'];
      //.png,.jpg,.bmp,.gif,.rar,.zip
      let limitImg = ['.png','.jpg','.bmp','.gif']

      let name = param.file.name;
      let i = name.lastIndexOf(".");
      let suffix = "";
      if (i > -1) {
        suffix = name.substring(i);
      }
      const number1 = limitImg.indexOf(suffix);
      if(number1<=-1){
        this.$message.error('只能上传.png,.jpg,.bmp,.gif文件')
        return;
      }
      const number = imgSuffix.indexOf(suffix);
      const size = param.file.size;
      if(number>-1){
        //图片上传
        const imgNumber = size/1024;
        console.log(imgNumber)
        if(imgNumber>2048){
          this.$message.success('您要上传的文件类型为图片,大小不能超过' + (2048 / 1024) + 'M')
          return;
        }
        api.uploadImg(formData).then(data=>{
          console.log('图片上传成功')
          this.editNumberValidateForm.urlList.push({urlType: 1,name:data.data,url:data.data})
          this.editNumberValidateForm.headImgUrl = data.data
        }).catch(response=>{
          console.log('图片上传失败')
          console.log(response)
        })
      }else{
        //文件上传
        const fileNumber = size/1024;
        console.log(fileNumber)
        if(fileNumber>10240){
          this.$message.success('您要上传的文件类型为附件,大小不能超过' + (10240 / 1024) + 'M')
          return;
        }
        api.uploadFile(formData).then(data=>{
          console.log('文件上传成功')
          this.editNumberValidateForm.urlList.push({urlType: 2,name:data.data,url:data.data})
          this.editNumberValidateForm.headImgUrl = data.data
        }).catch(response=>{
          console.log('文件上传失败')
          console.log(response)
        })
      }
    }
  },
  created() {

  },

}
</script>
<!--<style>

@import '@/css/dark-style-table.css';

</style>-->
<style lang="less" scoped>
.buyer_font_1vw{
  font-size: 1vw;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #414141;
}

/*/deep/ .el-pager li.active{
  color: rgb(247, 81, 69);

  cursor: default;
}
/deep/ .el-select .el-input.is-focus .el-input__inner{
  border-color: rgb(247, 81, 69);
}
/deep/ .el-input__inner:focus{
  border-color: rgb(247, 81, 69);
}*/

/deep/ .ivu-page {
  float: right;
  margin-right: 143px;
}

/deep/ .el-drawer__header {
  height: 52px;
  line-height: 20px;
  font-size: 16px;
  color: #17233d;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

/deep/ .el-drawer__container {
  margin-top: 58px;
}

/deep/ .el-textarea__inner{
  height: 20vh;
}

.page {


  position: relative;
  /*width: 80vw;*/
  height: 100%;
  background-color: rgb(255, 255, 255);
  .group_1{
    margin: 2vw 0vw 2vw 3vw;
    .group_1_left{
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #F75145;
    }
  }
  .group_2{
  }
  .group_3{
  }

  margin: 20px 35px;
  width: 97%;

  .title {
    color: #F56E65;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }

  .pane-container {
    height: 34px;

    .pane {
      width: 48px;
      height: 16px;
      font-size: 16px;
      margin-right: 40px;
      color: rgba(65, 65, 65, 1);

      /deep/ .el-button {
        color: #fff;
        background-color: #ed4014;
        border-color: #ed4014;
        height: 32px;
        width: 88px;
        padding: 8px 16px;
      }

      /deep/ .el-button:hover {
        color: #fff;
        background-color: #ee5630;
        border-color: #ee5630;
      }
    }

    .pane:active {
      color: #F56E65;
    }

    .pane:hover {
      color: #F56E65;
    }

    .pane-active {
      color: #F56E65;
    }

  }

  .table-container {
    margin-top: 20px;

    /deep/ .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    /deep/ .el-table thead {
      color: #515A6E;
    }
  }

  /deep/ .ivu-page {
    float: right;
    margin-right: 143px;
  }

  /deep/ .el-drawer__header {
    height: 52px;
    line-height: 20px;
    font-size: 16px;
    color: #17233d;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  /deep/ .el-drawer__container {
    margin-top: 58px;
  }

  .drawer {
    /deep/ .el-form-item__label {
      text-align: left;
      margin-left: 20px;
      width: 79px !important;
    }

    /deep/ .el-form-item__content {
      margin-left: 100px !important;
      width: 421px;
    }

    /deep/ .el-cascader {
      width: 421px;
    }
    /deep/ .el-button--primary {
      width: 90px;
      height: 32px;
      color: #fff;
      background-color: #ed4014;
      border-color: #ed4014;
      padding-top: 9px;
    }
    /deep/ .el-button+.el-button {
      margin-left: 35px;
      width: 90px;
      height: 32px;
      padding-top: 9px;
    }


  }
}

@media screen and (max-width: 767px) {

}

/* 4. 大桌面显示器（大于等于768px）布局容器宽度为768px */
@media screen and (min-width: 768px) {
}


</style>
